import cn from 'classnames';
import Image from 'next/image';
import { useLayoutEffect, useState } from 'react';

import styles from './PageBackground.module.scss';
import { PageBackgroundProps } from './PageBackground.props';

export const PageBackground = ({ color, image, className }: PageBackgroundProps) => {
  const [height, setHeight] = useState(0);

  const handleResize = () => {
    const element = document.getElementById('PageBackground');

    if (element) {
      element.style.height = element.clientHeight + 'px';
      setHeight(element.clientHeight + element.offsetTop);
    }
  };

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={cn(styles.PageBackground, className)} style={{ height: height }}>
      <div className={cn(styles.PageBackground__Background, `background-${color}`)} />

      <div className={styles.PageBackground__Image}>
        <Image src={image} layout="fill" objectFit="contain" objectPosition="bottom" />
      </div>
    </div>
  );
};
