import React from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import { VacancyCategoryCardProps } from './VacancyCategoryCard.props';

export const VacancyCategoryCard = ({ className, vacancy }: VacancyCategoryCardProps) => {
  const { t } = useTranslation();

  return (
    <CategoryCard
      href={`${t('COMMON.ROUTES.VACANCIES')}/${vacancy._slug}`}
      className={className}
      thumbnailSrc={vacancy.image[0].cdn_files[0].full}
      categoryTitle={t('DOMAIN.VACANCY.SINGLE')}
      categoryColor={COLORS.GREEN}
      title={vacancy.title}
      tags={vacancy.branche}>
      <div
        dangerouslySetInnerHTML={{
          __html: `${vacancy.company[0].title}<br/>${vacancy.employment_type[0].body}`,
        }}
      />
    </CategoryCard>
  );
};
