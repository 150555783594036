import { CheckboxInput } from './CheckboxInput/CheckboxInput';
import { FormField } from './Field/FormField';
import { FileInput } from './FileInput/FileInput';
import { Form as FormComponent } from './Form/Form';
import { FormRow } from './Row/FormRow';
import { SelectInput } from './SelectInput/SelectInput';
import { TagCloudInput } from './TagCloudInput/TagCloudInput';
import { TextAreaInput } from './TextAreaInput/TextAreaInput';
import { TextInput } from './TextInput/TextInput';

export const Form = {
  Form: FormComponent,
  Field: FormField,
  Row: FormRow,
  TextInput,
  FileInput,
  CheckboxInput,
  TextAreaInput,
  SelectInput,
  TagCloudInput,
};

export * from './CheckboxInput/CheckboxInput';
export * from './CheckboxInput/CheckboxInput.props';
export * from './Field/FormField';
export * from './Field/FormField.props';
export * from './FileInput/FileInput';
export * from './FileInput/FileInput.props';
export * from './Form/Form';
export * from './Form/Form.props';
export * from './Row/FormRow';
export * from './Row/FormRow.props';
export * from './SelectInput/SelectInput';
export * from './SelectInput/SelectInput.props';
export * from './TagCloudInput/TagCloudInput';
export * from './TagCloudInput/TagCloudInput.props';
export * from './TextAreaInput/TextAreaInput';
export * from './TextAreaInput/TextAreaInput.props';
export * from './TextInput/TextInput';
export * from './TextInput/TextInput.props';
