import cn from 'classnames';
import { createElement } from 'react';

import { Blockquote, Button, CTA, ImageCarousel, Video } from '@Components/ui';
import {
  IPreprElement,
  IPreprElementBlockquote,
  IPreprElementButton,
  IPreprElementCarousel,
  IPreprElementCTA,
  IPreprElementText,
  IPreprElementVideo,
} from '@Constants/interfaces';

import styles from './PreprDragAndDrop.module.scss';
import { PreprDragAndDropProps } from './PreprDragAndDrop.props';

export const PreprDragAndDrop = ({ elements, page, className }: PreprDragAndDropProps) => {
  const getText = (item: IPreprElementText) => {
    if (item.format) {
      return createElement(`${item.format.toLowerCase()}`, null, item.body);
    }

    return <div className="HTML" dangerouslySetInnerHTML={{ __html: item.body }} />;
  };

  const getVideo = (item: IPreprElementVideo) => {
    return <Video id={item.youtube_id} />;
  };

  const getCTA = (item: IPreprElementCTA) => {
    return <CTA {...item} />;
  };

  const getBlockquote = (item: IPreprElementBlockquote) => {
    return <Blockquote quote={item.blockquote} />;
  };

  const getButton = (item: IPreprElementButton) => {
    return <Button href={item.url}>{item.label}</Button>;
  };

  const getCarousel = (item: IPreprElementCarousel) => {
    return (
      <ImageCarousel
        images={item.images.map((image) => image.cdn_files[0].full)}
        height={310}
        thumbnails
        pagination
      />
    );
  };

  const getElement = (item: IPreprElement) => {
    switch (item.__typename) {
      case 'Text':
        return getText(item as IPreprElementText);
      case 'Video':
        return getVideo(item as IPreprElementVideo);
      case 'Carousel':
        return getCarousel(item as IPreprElementCarousel);
      case 'CTA':
        return getCTA(item as IPreprElementCTA);
      case 'Blockquote':
        return getBlockquote(item as IPreprElementBlockquote);
      case 'Button':
        return getButton(item as IPreprElementButton);
      default:
        return <strong>{item.__typename} Element is not found!</strong>;
    }
  };

  const getElements = () => {
    const ELEMENT_COLUMNS = {
      Text: 'col-lg-6',
      Blockquote: 'col-lg-6',
      Button: 'col-lg-6',
      CTA: 'col-lg-8',
      Video: 'col-lg-8',
      Carousel: 'col-lg-8',
    };

    return elements.map((element) => {
      const columns = page ? ELEMENT_COLUMNS[element.__typename] : '';

      return (
        <div
          key={element._id}
          className={cn(styles.Row, styles[`Row--${element.__typename}`], columns)}>
          {getElement(element)}
        </div>
      );
    });
  };

  return (
    <div className={cn(styles.PreprDragAndDrop, className)}>
      {page ? (
        <div className="container">
          <div className="row justify-content-center">{getElements()}</div>
        </div>
      ) : (
        getElements()
      )}
    </div>
  );
};
