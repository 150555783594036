import cn from 'classnames';

import { Button } from '@Components/ui';
import { formatString } from '@Utils/StringUtils';

import styles from './CTA.module.scss';
import { CTAProps } from './CTA.props';

export const CTA = ({ title, button_label, button_url, className }: CTAProps) => {
  return (
    <div className={cn(styles.CTA, className)}>
      <h2 dangerouslySetInnerHTML={{ __html: formatString(title) }} />
      <Button className="mt-xs" href={button_url}>
        {button_label}
      </Button>
    </div>
  );
};
