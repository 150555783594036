import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import Yup from '@Utils/YupUtils';

import styles from './AuctionForm.module.scss';
import { AuctionFormProps } from './AuctionForm.props';

export const AuctionForm = ({ handleSubmit, className }: AuctionFormProps) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('COMMON.FORMS.VALIDATION.STRING.EMAIL')).required(),
    agreed: Yup.boolean().required().oneOf([true], t('COMMON.FORMS.VALIDATION.MIXED.REQUIRED')),
  });

  return (
    <div className={cn(styles.AuctionForm, className)}>
      <h1>{t('FORMS.AUCTION_FORM.TITLE')}</h1>

      <Form.Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form.Field label={t('FORMS.AUCTION_FORM.LABEL.EMAIL')} isRequired={true}>
          <Form.TextInput name="email" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
        </Form.Field>

        <Form.Field isRequired={true}>
          <Form.CheckboxInput
            name="agreed"
            options={[{ key: true, value: t('FORMS.AUCTION_FORM.LABEL.AGREED') }]}
          />
        </Form.Field>
        <Button type="submit">{t('FORMS.AUCTION_FORM.SUBMIT')}</Button>
      </Form.Form>
    </div>
  );
};
