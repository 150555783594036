import cn from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { IconLink } from '@Components/ui';
import { getRichText } from '@Utils/PrismicUtils';

import styles from './Single.module.scss';
import { SingleProps } from './Single.props';

export const Single = ({
  title,
  slug,
  card,
  introduction,
  carousel,
  thumbnail,
  children,
  className,
  theme,
}: SingleProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.Single, className)}>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-xl-2" />
                <div className="col-xl-10">
                  <h1 className="h2 mb-md" dangerouslySetInnerHTML={{ __html: title }} />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.Single__Content}>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="mb-lg image">
                      <Image src={thumbnail} layout="fill" />
                    </div>
                  </div>
                </div>
                {introduction && (
                  <div className="row">
                    <div className="col-xl-2" />
                    <div className="col-xl-10">{getRichText(introduction, 'mb-lg')}</div>
                  </div>
                )}
                {carousel && (
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-lg image">{carousel}</div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-xl-2" />
                  <div className="col-xl-10">{children}</div>
                </div>
              </div>

              <div className="col-lg-1 hidden-md-down" />

              <div className="col-lg-5 mt-md-lg">
                {card}

                <div className="text-right mt-xs">
                  <IconLink
                    href={`${t('COMMON.ROUTES.COMPANIES')}/${slug}`}
                    children={t('GLOBALS.GO_TO_COMPANY')}
                    iconColor={theme}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
