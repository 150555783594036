import i18next from 'i18next';

import data from '@Locales/index';

export const i18NextInitializer = (locale: string) => {
  i18next.init({
    lng: locale,
    resources: data,
    react: {
      useSuspense: false,
      bindI18n: 'languageChanged loaded',
      nsMode: 'default',
    },
    interpolation: {
      format: (value, format) => {
        if (format === 'lowercase') return value.toLowerCase();
        return value;
      },
    },
  });
};
