import cn from 'classnames';
import React from 'react';

import { Tag } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { getButtonID } from '@Utils/StringUtils';

import { BaseCard } from '../BaseCard/BaseCard';
import { Button } from '../Button/Button';
import styles from './PerksCard.module.scss';
import { PerksCardProps } from './PerksCard.props';

export const PerksCard = ({
  className,
  title,
  price,
  children,
  linkLabel,
  color,
  featured,
  linkUrl,
  ...props
}: PerksCardProps) => {
  const backgroundColor = featured ? COLORS.BLUE : COLORS.WHITE;
  const textColorPrimary = featured ? COLORS.WHITE : color;
  const textColorSecondary = featured ? COLORS.WHITE : COLORS.BLACK;

  return (
    <BaseCard
      className={cn(styles.PerksCard, className)}
      backgroundColor={backgroundColor}
      borderColor={backgroundColor}
      withShadow
      {...props}>
      {!!featured && (
        <Tag
          backgroundColor={COLORS.PRIMARY}
          borderColor={COLORS.PRIMARY}
          textColor={COLORS.WHITE}
          className={styles.PerksCard__Tag}>
          {featured}
        </Tag>
      )}
      <h2 className={`color-${textColorPrimary} mb-1`}>{title.toUpperCase()}</h2>
      <p
        className={cn(
          `color-children-${textColorSecondary}`,
          `li-before-children-${textColorPrimary}`,
          `note`,
        )}>
        {price}
      </p>
      <div
        className={cn(
          `color-children-${textColorSecondary}`,
          `li-before-children-${textColorPrimary}`,
        )}>
        {children}
      </div>
      <div className={cn(styles.PerksCard__Button)}>
        <Button id={getButtonID(linkLabel)} color={textColorPrimary} href={linkUrl}>
          <div className={`color-${backgroundColor}`}>{linkLabel}</div>
        </Button>
      </div>
    </BaseCard>
  );
};
