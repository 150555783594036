import cn from 'classnames';
import React from 'react';

import { COLORS } from '@Constants/enums';

import styles from './Blockquote.module.scss';
import { BlockquoteProps } from './Blockquote.props';

export const Blockquote = ({
  className,
  quote,
  quoteColor = COLORS.PRIMARY,
  textColor = COLORS.BLACK,
}: BlockquoteProps) => {
  return (
    <blockquote
      className={cn(
        styles.Blockquote,
        'h2',
        `color-${textColor}`,
        `before-after-color-${quoteColor}`,
        className,
      )}>
      {quote}
    </blockquote>
  );
};
