import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInputProps } from './TextInput.props';

export const TextInput = ({ name, type = 'text', placeholder, ...props }: TextInputProps) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <input
      type={type}
      placeholder={placeholder || t('GLOBALS.DEFAULT_PLACEHOLDER')}
      id={name}
      {...(name ? register(name) : null)}
      {...props}
    />
  );
};
