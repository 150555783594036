import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useStepsFormContext } from '@Components/containers';
import { Button } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { formatString } from '@Utils/StringUtils';

import styles from './FormStep.module.scss';
import { FormStepProps } from './FormStep.props';

export const FormStep = ({
  className,
  title,
  description,
  submitButton,
  disabled,
  children,
}: FormStepProps) => {
  const { t } = useTranslation();
  const { canGoBack, canGoNext, toPrevStep, toNextStep, error } = useStepsFormContext();

  return (
    <div className={cn(styles.FormStep, className)}>
      <div className="row">
        <div className="col-sm-6">
          <h2
            dangerouslySetInnerHTML={{
              __html: formatString(title),
            }}
          />
          <p>{description}</p>
        </div>
      </div>

      {children}

      {error && !canGoNext && <div className={cn('Error', styles.FormStep__Error)}>{error}</div>}

      <div className={styles.FormStep__ButtonWrapper}>
        {canGoBack && (
          <a className={styles.FormStep__BackButton} onClick={toPrevStep}>
            {t('COMMON.FORMS.PREVIOUS_BUTTON')}
          </a>
        )}
        {canGoNext ? (
          <Button disabled={disabled} color={COLORS.GREEN} onClick={toNextStep}>
            {t('COMMON.FORMS.NEXT_BUTTON')}
          </Button>
        ) : (
          <Button color={COLORS.GREEN} type="submit">
            {submitButton || t('COMMON.FORMS.SUBMIT_BUTTON')}
          </Button>
        )}
      </div>
    </div>
  );
};
