import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import Props from './Portal.props';

export const Portal = ({ children }: Props) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  if (mounted) {
    const body = document.querySelector('body');
    return body ? createPortal(children, body) : null;
  }

  return null;
};
