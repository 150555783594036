import { captureException } from '@sentry/nextjs';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepByStepForm } from '@Components/containers';
import { FormStep } from '@Components/forms';
import { Form } from '@Components/ui';
import { AUTH_API_ERRORS } from '@Constants/apiErrors';
import { useFieldsValidation } from '@Hooks/formHooks';
import { BackendClient } from '@Utils/BackendUtils';
import Yup from '@Utils/YupUtils';

import styles from './RegisterResidentForm.module.scss';
import {
  RegisterResidentFormProps,
  RegisterResidentFormValues,
} from './RegisterResidentForm.props';

const RegisterResidentFormStepOne = () => {
  const { t } = useTranslation();
  const isValid = useFieldsValidation(['name', 'postal_code', 'house_number', 'street', 'city']);

  return (
    <FormStep
      title={t('DOMAIN.REGISTER.RESIDENT.FORM.0.TITLE')}
      description={t('DOMAIN.REGISTER.RESIDENT.FORM.0.DESCRIPTION')}
      disabled={!isValid}>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.NAME_LABEL')}>
          <Form.TextInput required={true} name="name" />
        </Form.Field>
        <Form.Row>
          <Form.Field isRequired label={t('COMMON.FORMS.POSTAL_CODE_LABEL')}>
            <Form.TextInput required={true} name="postal_code" />
          </Form.Field>
          <Form.Field isRequired label={t('COMMON.FORMS.HOUSE_NUMBER_LABEL')}>
            <Form.TextInput required={true} name="house_number" />
          </Form.Field>
        </Form.Row>
      </Form.Row>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.STREET_LABEL')}>
          <Form.TextInput required={true} name="street" />
        </Form.Field>
        <Form.Field isRequired label={t('COMMON.FORMS.CITY_LABEL')}>
          <Form.TextInput required={true} name="city" />
        </Form.Field>
      </Form.Row>
      <Form.Field label={t('COMMON.FORMS.PHONE_NUMBER_LABEL')}>
        <Form.TextInput name="phone_number" />
      </Form.Field>
    </FormStep>
  );
};

const RegisterResidentFormStepTwo = () => {
  const { t } = useTranslation();

  return (
    <FormStep
      title={t('DOMAIN.REGISTER.RESIDENT.FORM.1.TITLE')}
      description={t('DOMAIN.REGISTER.RESIDENT.FORM.1.DESCRIPTION')}>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.EMAIL_LABEL')}>
          <Form.TextInput name="email" type="email" required={true} />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.PASSWORD_LABEL')}>
          <Form.TextInput name="password" type="password" required={true} />
        </Form.Field>
        <Form.Field isRequired label={t('COMMON.FORMS.REPEAT_PASSWORD_LABEL')}>
          <Form.TextInput name="password_repeat" type="password" required={true} />
        </Form.Field>
      </Form.Row>

      <Form.Row>
        <Form.Field label={''}>
          <Form.CheckboxInput
            name="agree_terms"
            options={[
              {
                key: true,
                value: t('COMMON.FORMS.AGREE_TO_TERMS_AND_CONDITIONS_LABEL_FREE'),
              },
            ]}
          />
        </Form.Field>
      </Form.Row>
    </FormStep>
  );
};

export const RegisterResidentForm = ({ className }: RegisterResidentFormProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const router = useRouter();

  const getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required(),
      postal_code: Yup.string().required(),
      house_number: Yup.string().required(),
      street: Yup.string().required(),
      city: Yup.string().required(),
      phone_number: Yup.string(),
      email: Yup.string().required().email(),
      password: Yup.string().required().min(8).max(32),
      password_repeat: Yup.string().equals(
        [Yup.ref('password'), null],
        t('COMMON.FORMS.PASSWORD_DO_NOT_MATCH_MESSAGE'),
      ),
      agree_terms: Yup.boolean()
        .required()
        .oneOf([true], t('COMMON.FORMS.VALIDATION.MIXED.REQUIRED')),
    });
  };

  const handleSubmit = async (values: RegisterResidentFormValues) => {
    try {
      await BackendClient.post('/newsletter', {
        EMAIL: values.email,
        FNAME: values.name,
      });
    } catch {}

    try {
      await BackendClient.post('/subscribe/resident', values);

      router.replace(t('COMMON.ROUTES.SUBSCRIBE_THANK_YOU'));
    } catch (e) {
      if (e.response.data === AUTH_API_ERRORS.EMAIL_ALREADY_IN_USE) {
        setError(t('DOMAIN.AUTHENTICATION.REGISTER.ERRORS.EMAIL_IN_USE'));
      } else {
        setError(t('GLOBALS.ERRORS.GENERIC'));
        captureException(e);
      }
    }
  };

  return (
    <div className={cn(styles.RegisterResidentForm, className)}>
      <StepByStepForm
        error={error}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
        steps={[<RegisterResidentFormStepOne />, <RegisterResidentFormStepTwo />]}
      />
    </div>
  );
};
