import cn from 'classnames';
import Image from 'next/image';

import styles from './PageIntro.module.scss';
import { PageIntroProps } from './PageIntro.props';

export const PageIntro = ({ label, title, description, image, className }: PageIntroProps) => {
  const actualImage = image?.cdn_files?.[0].full;

  return (
    <div className={cn(styles.PageIntro, className)}>
      <div className={styles.TextContent}>
        {!!label && <p className={styles.Label}>{label}</p>}
        {!!title && <h1 className={styles.Title}>{title}</h1>}
        {!!description && (
          <div className={styles.Description} dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
      <div className={styles.ImageContainer}>
        {!!actualImage && <Image src={actualImage} layout="fill" />}
      </div>
    </div>
  );
};
