import React from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import { NewsCategoryCardProps } from './NewsCategoryCard.props';

export const NewsCategoryCard = ({ className, news }: NewsCategoryCardProps) => {
  const { t } = useTranslation();

  return (
    <CategoryCard
      className={className}
      href={`${t('COMMON.ROUTES.NEWS')}/${news._slug}`}
      thumbnailSrc={news.image[0].cdn_files[0].full}
      categoryTitle={t('DOMAIN.NEWS.SINGLE')}
      categoryColor={COLORS.SECONDARY}
      title={news.title}
      tags={news.branche}>
      {news.company[0].title}
    </CategoryCard>
  );
};
