import cn from 'classnames';

import { COLORS } from '@Constants/enums';

import { IconProps } from './Icon.props';

export const Icon = ({
  icon,
  className,
  type = 'solid',
  color = COLORS.BLACK,
  colorImportant = false,
  size = 20,
  ...props
}: IconProps) => {
  const faType = {
    solid: 'fas',
    regular: 'far',
    light: 'fal',
    brands: 'fab',
  }[type];

  return (
    <i
      className={cn(`color${colorImportant ? '-important' : ''}-${color}`, `fa-${icon}`, faType, {
        [`${className}`]: className,
      })}
      style={{ fontSize: `${size}px` }}
      {...props}
    />
  );
};
