import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { Icon } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './CircledIconButton.module.scss';
import { CircledIconButtonProps } from './CircledIconButton.props';

export const CircledIconButton = ({
  className,
  icon,
  href,
  iconType,
  backgroundColor = COLORS.PRIMARY,
  tintColor,
  onClick,
  ...props
}: CircledIconButtonProps) => {
  const classNames = cn([styles.CircledIconButton, `background-${backgroundColor}`, className]);

  if (href) {
    return (
      <Link href={href}>
        <a href={href} className={classNames} {...props}>
          <Icon icon={icon} type={iconType} color={tintColor ?? COLORS.WHITE} size={14} />
        </a>
      </Link>
    );
  }
  return (
    <button className={classNames} onClick={onClick}>
      <Icon icon={icon} type={iconType} color={tintColor ?? COLORS.WHITE} size={14} />
    </button>
  );
};
