import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { COLORS } from '@Constants/enums';

import { Icon } from '../Icon/Icon';
import styles from './IconLink.module.scss';
import { IconLinkProps } from './IconLink.props';

export const IconLink = ({
  children,
  href,
  className,
  left = false,
  icon,
  iconColor = COLORS.PRIMARY,
}: IconLinkProps) => {
  return (
    <Link href={href}>
      <a className={cn(styles.IconLink, className)} href={href}>
        {left && (
          <Icon
            icon={icon || 'long-arrow-left'}
            type="regular"
            color={iconColor}
            className={cn(styles.IconLink__Icon, styles['IconLink__Icon--Left'])}
          />
        )}
        {children}
        {left || (
          <Icon
            icon={icon || 'long-arrow-right'}
            type="regular"
            color={iconColor}
            className={styles.IconLink__Icon}
          />
        )}
      </a>
    </Link>
  );
};

export default IconLink;
