import cn from 'classnames';
import Image from 'next/image';

import { COLORS } from '@Constants/enums';

import styles from './BaseCard.module.scss';
import { BaseCardProps } from './BaseCard.props';

export const BaseCard = ({
  className,
  backgroundColor = COLORS.WHITE,
  borderColor = COLORS.LIGHT_GRAY,
  thumbnail,
  onClick,
  title,
  height = 'stretch',
  children,
  withShadow,
  ...props
}: BaseCardProps) => {
  const classNames = cn(
    styles.BaseCard,
    `background-${backgroundColor}`,
    `border-${borderColor}`,
    className,
    {
      [styles['BaseCard--Stretch']]: height === 'stretch',
    },
    {
      [styles['BaseCard--Clickable']]: onClick != null,
    },
    {
      [styles['BaseCard--With-Shadow']]: withShadow,
    },
  );

  return (
    <div className={classNames} onClick={onClick} {...props}>
      {thumbnail && (
        <div className={styles.BaseCard__Header}>
          <Image src={thumbnail} layout="fill" objectFit="cover" />
        </div>
      )}

      <div className={styles.BaseCard__Content}>
        {title && <h3>{title}</h3>}
        {children}
      </div>
    </div>
  );
};
