import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { BaseCard, Tag } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { IHomepageExplanationBlock } from '@Modules/HomepageModule';
import { getRichText } from '@Utils/PrismicUtils';

import styles from './USPBlock.module.scss';
import { USPBlockProps } from './USPBlock.props';

export const USPBlock = ({ title, items }: USPBlockProps) => {
  const getTagColor = (cardNumber: number) => {
    switch (cardNumber) {
      case 0:
        return COLORS.PRIMARY;
      case 1:
        return COLORS.PURPLE;
      default:
        return COLORS.GREEN;
    }
  };

  return (
    <div className={styles.USPBlock}>
      <div className={styles.USPBlock__Header}>
        <h3 className={styles.USPBlock__HeaderTitle}>{title}</h3>
        <div className={styles.USPBlock__HeaderImage} />
      </div>

      <div className={styles.USPBlock__Body}>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          className={styles.USPBlock__Slider}
          breakpoints={{
            '640': {
              slidesPerView: 2,
              spaceBetween: 20,
            },

            '1024': {
              slidesPerView: 3,
              spaceBetween: 20,
              allowTouchMove: false,
            },
          }}>
          {items.map((item: IHomepageExplanationBlock, key: number) => (
            <SwiperSlide key={key} className={styles.USPBlock__Slide}>
              <div className={styles.USPBlock__Tags}>
                <Tag
                  backgroundColor={getTagColor(key)}
                  textColor={COLORS.WHITE}
                  borderColor={COLORS.TRANSPARENT}>
                  {item.tag}
                </Tag>
              </div>

              <BaseCard
                className={styles.USPBlock__Card}
                title={item.title}
                thumbnail={item.image[0].cdn_files[0].full}>
                {getRichText(item.body)}
              </BaseCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
