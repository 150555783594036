import { captureException } from '@sentry/nextjs';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepByStepForm } from '@Components/containers';
import { FormStep } from '@Components/forms';
import { Form } from '@Components/ui';
import { AUTH_API_ERRORS } from '@Constants/apiErrors';
import { useFieldsValidation } from '@Hooks/formHooks';
import { BackendClient } from '@Utils/BackendUtils';
import Yup from '@Utils/YupUtils';

import styles from './RegisterCompanyForm.module.scss';
import { RegisterCompanyFormProps, RegisterCompanyFormValues } from './RegisterCompanyForm.props';

const RegisterCompanyFormStepOne = () => {
  const { t } = useTranslation();
  const isValid = useFieldsValidation([
    'name',
    'company_name',
    'postal_code',
    'house_number',
    'street',
    'city',
    'phone_number',
  ]);

  return (
    <FormStep
      disabled={!isValid}
      title={t('DOMAIN.REGISTER.COMPANY.FORM.0.TITLE')}
      description={t('DOMAIN.REGISTER.COMPANY.FORM.0.DESCRIPTION')}>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.NAME_LABEL')}>
          <Form.TextInput required={true} name="name" />
        </Form.Field>
        <Form.Field isRequired label={t('COMMON.FORMS.BUSINESS_NAME_LABEL')}>
          <Form.TextInput required={true} name="company_name" />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Row>
          <Form.Field isRequired label={t('COMMON.FORMS.POSTAL_CODE_LABEL')}>
            <Form.TextInput
              required={true}
              name="postal_code"
              placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER_SHORT')}
            />
          </Form.Field>
          <Form.Field isRequired label={t('COMMON.FORMS.HOUSE_NUMBER_LABEL')}>
            <Form.TextInput
              required={true}
              name="house_number"
              placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER_SHORT')}
            />
          </Form.Field>
        </Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.STREET_LABEL')}>
          <Form.TextInput required={true} name="street" />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.CITY_LABEL')}>
          <Form.TextInput required={true} name="city" />
        </Form.Field>
        <Form.Field isRequired label={t('COMMON.FORMS.PHONE_NUMBER_LABEL')}>
          <Form.TextInput required={true} name="phone_number" />
        </Form.Field>
      </Form.Row>
    </FormStep>
  );
};

const RegisterCompanyFormStepTwo = () => {
  const { t } = useTranslation();

  return (
    <FormStep
      title={t('DOMAIN.REGISTER.COMPANY.FORM.1.TITLE')}
      description={t('DOMAIN.REGISTER.COMPANY.FORM.1.DESCRIPTION')}>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.EMAIL_LABEL')}>
          <Form.TextInput name="email" type="email" required={true} />
        </Form.Field>
      </Form.Row>
      <Form.Row>
        <Form.Field isRequired label={t('COMMON.FORMS.PASSWORD_LABEL')}>
          <Form.TextInput name="password" type="password" required={true} />
        </Form.Field>
        <Form.Field isRequired label={t('COMMON.FORMS.REPEAT_PASSWORD_LABEL')}>
          <Form.TextInput name="password_repeat" type="password" required={true} />
        </Form.Field>
      </Form.Row>

      <Form.Row>
        <Form.Field label={''}>
          <Form.CheckboxInput
            name="agree_terms"
            options={[
              {
                key: true,
                value: t('COMMON.FORMS.AGREE_TO_TERMS_AND_CONDITIONS_LABEL'),
              },
            ]}
          />
        </Form.Field>
        <Form.Field label={''}>
          <Form.CheckboxInput
            name="agree_newsletter"
            options={[
              {
                key: 'Key',
                value: t('COMMON.FORMS.AGREE_TO_NEWSLETTER_LABEL'),
              },
            ]}
          />
        </Form.Field>
      </Form.Row>
    </FormStep>
  );
};

export const RegisterCompanyForm = ({ className }: RegisterCompanyFormProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [error, setError] = useState('');

  const getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required(),
      company_name: Yup.string().required(),
      postal_code: Yup.string().required(),
      house_number: Yup.string().required(),
      street: Yup.string().required(),
      city: Yup.string().required(),
      phone_number: Yup.string().required(),
      email: Yup.string().required().email(),
      password: Yup.string().required().min(8).max(32),
      password_repeat: Yup.string().equals(
        [Yup.ref('password'), null],
        t('COMMON.FORMS.PASSWORD_DO_NOT_MATCH_MESSAGE'),
      ),
      agree_terms: Yup.boolean()
        .required()
        .oneOf([true], t('COMMON.FORMS.VALIDATION.MIXED.REQUIRED')),
    });
  };

  const handleSubmit = async (values: RegisterCompanyFormValues) => {
    if (values.agree_newsletter) {
      try {
        await BackendClient.post('/newsletter', {
          EMAIL: values.email,
          FNAME: values.name,
        });
      } catch {}
    }

    try {
      const { data } = await BackendClient.post(`/subscribe/company`, values);
      router.push({
        pathname: data.url,
        query: {
          email: data.email,
        },
      });
    } catch (e) {
      if (e.response.data === AUTH_API_ERRORS.EMAIL_ALREADY_IN_USE) {
        setError(t('DOMAIN.AUTHENTICATION.REGISTER.ERRORS.EMAIL_IN_USE'));
      } else {
        setError(t('GLOBALS.ERRORS.GENERIC'));
        captureException(e);
      }
    }
  };

  return (
    <div className={cn(styles.RegisterCompanyForm, className)}>
      <StepByStepForm
        error={error}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema()}
        steps={[<RegisterCompanyFormStepOne />, <RegisterCompanyFormStepTwo />]}
      />
    </div>
  );
};
