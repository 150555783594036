import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@Components/ui/Button/Button';

import { FormProps } from './Form.props';

export function Form<T>({
  validationSchema,
  onSubmit,
  onChange,
  children,
  initialValues,
  submitLabel,
  className,
  id,
}: FormProps<T>) {
  const methods = useForm<T>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const values = methods.watch();
  const valuesString = JSON.stringify(values);

  useEffect(() => {
    onChange && onChange(values, methods.formState.isValid);
  }, [valuesString]);

  return (
    <FormProvider {...methods}>
      <form id={id} className={className} onSubmit={onSubmit && methods.handleSubmit(onSubmit)}>
        {children}
        {submitLabel && <Button type="submit">{submitLabel}</Button>}
      </form>
    </FormProvider>
  );
}
