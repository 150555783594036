import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useStepsFormContext } from '@Components/containers';
import { __CLIENT_SIDE__ } from '@Constants/environment';

export const useFieldsValidation = (fields: string[]) => {
  const [isValid, setIsValid] = useState(false);
  const { watch } = useFormContext();
  const stepsFormContext = useStepsFormContext();
  const values = watch();

  if (!__CLIENT_SIDE__ || !values) {
    return false;
  }

  (async () => {
    try {
      await Promise.all(
        fields.map((field) => {
          return stepsFormContext.validationSchema?.validateAt(field, values);
        }),
      );

      setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  })();

  return isValid;
};
