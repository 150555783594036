import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { BaseCard, Tag } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './CategoryCard.module.scss';
import { CategoryCardProps, CategoryTagProps } from './CategoryCard.props';

const CategoryTag = ({ className, title, color }: CategoryTagProps) => {
  return <div className={cn(className, `background-${color}`)}>{title}</div>;
};

export const CategoryCard = ({
  className,
  href,
  thumbnailSrc,
  categoryColor = COLORS.PRIMARY,
  categoryTitle,
  title,
  tags,
  children,
}: CategoryCardProps) => {
  const isCategoryTagOverlay = thumbnailSrc != null;

  const renderCard = () => {
    return (
      <BaseCard
        className={cn(styles.CategoryCard, className)}
        thumbnail={thumbnailSrc || '/images/Placeholder.png'}>
        <div className={styles.CategoryCard__Body}>
          {title && (
            <h3
              className={cn(
                styles.CategoryCard__Title,
                styles[`CategoryCard__Title--HoverColor-${categoryColor}`],
              )}>
              {title}
            </h3>
          )}
          {categoryTitle && (
            <CategoryTag
              className={cn(
                styles.CategoryCard__CategoryTag,
                {
                  [styles['CategoryCard__CategoryTag--Overlay']]: isCategoryTagOverlay,
                },
                {
                  [styles['CategoryCard__CategoryTag--Child']]: !isCategoryTagOverlay,
                },
                `background-${categoryColor}`,
              )}
              title={categoryTitle}
              color={categoryColor}
            />
          )}
          {children}
        </div>

        <div className={styles.CategoryCard__Tags}>
          {tags &&
            tags.map((tag) => {
              return (
                <Tag
                  className={cn(
                    styles.CategoryCard__Tag,
                    styles[`CategoryCard__Tag--HoverColor-${categoryColor}`],
                  )}
                  key={tag._id}>
                  {tag.body}
                </Tag>
              );
            })}
        </div>
      </BaseCard>
    );
  };

  return href ? (
    <Link href={href}>
      <a className={styles.CategoryCard__Link} href={href}>
        {renderCard()}
      </a>
    </Link>
  ) : (
    renderCard()
  );
};
