import React from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import { GiveawayCategoryCardProps } from './GiveawayCategoryCard.props';

export const GiveawayCategoryCard = ({ className, giveaway }: GiveawayCategoryCardProps) => {
  const { t } = useTranslation();

  return (
    <CategoryCard
      className={className}
      href={`${t('COMMON.ROUTES.GIVEAWAY')}/${giveaway._slug}`}
      thumbnailSrc={giveaway.image[0].cdn_files[0].full}
      categoryTitle={giveaway.type[0].body}
      categoryColor={giveaway.type[0].body === 'Winactie' ? COLORS.PURPLE : COLORS.EXTEREME_PINK}
      title={giveaway.question}
      tags={giveaway.branche}>
      <p>{giveaway.company[0].title}</p>
    </CategoryCard>
  );
};
