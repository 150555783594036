import cn from 'classnames';

import styles from './Video.module.scss';
import { VideoProps } from './Video.props';

export const Video = ({ id, className }: VideoProps) => {
  return (
    <div className={cn(styles.Video, className)}>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${id}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={styles.Frame}
      />
    </div>
  );
};
