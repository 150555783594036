import cn from 'classnames';
import Image from 'next/image';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icon } from '@Components/ui';

import styles from './Testimonial.module.scss';
import { TestimonialItemProps, TestimonialProps } from './Testimonial.props';

const TestimonialItem = ({ image, name, title, quote }: TestimonialItemProps) => {
  return (
    <div className={styles.TestimonialItem}>
      <div className={styles.TestimonialItem__Author}>
        <div className={styles.TestimonialItem__AuthorImage}>
          <Image src={image} width={65} height={65} />
        </div>

        <div className={styles.TestimonialItem__AuthorInfo}>
          <strong>{name}</strong>
          {title}
        </div>
      </div>

      <blockquote className={styles.TestimonialItem__Quote}>{quote}</blockquote>
    </div>
  );
};

export const Testimonial = ({ items, className }: TestimonialProps) => {
  const swiperRef = useRef<any>(null);

  const setSwiper = (instance: any) => {
    swiperRef.current = instance;
  };

  const getSlider = () => (
    <div className={styles.Testimonial__Slider}>
      <div
        onClick={() => (swiperRef.current as any)?.slidePrev()}
        className={styles.Testimonial__Prev}>
        <Icon icon="chevron-left" />
      </div>

      <Swiper onSwiper={setSwiper} slidesPerView={1} loop={true}>
        {items.map((item: TestimonialItemProps) => (
          <SwiperSlide key={item.name + item.title + item.quote}>
            <TestimonialItem {...item} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        onClick={() => (swiperRef.current as any)?.slideNext()}
        className={styles.Testimonial__Next}>
        <Icon icon="chevron-right" />
      </div>
    </div>
  );

  return (
    <div className={cn(styles.Testimonial, className)}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {items.length > 1 ? getSlider() : <TestimonialItem {...items[0]} />}
          </div>
        </div>
      </div>
    </div>
  );
};
