import cn from 'classnames';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import SwiperCore, { Navigation, Pagination, Thumbs } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types/components/pagination';

import { Icon } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './ImageCarousel.module.scss';
import { ImageCarouselProps } from './ImageCarousel.props';

SwiperCore.use([Navigation, Pagination, Thumbs]);

export const ImageCarousel = ({
  images,
  className,
  pagination = false,
  thumbnails = false,
  width = '100%',
  height = 500,
}: ImageCarouselProps) => {
  const [thumbnailSwiper, setThumbnailSwiper] = useState<any>(null);
  const swiperRef = useRef<any>(null);

  const fraction = {
    el: '.swiper-pagination',
    type: 'fraction',
    renderFraction: (currentClass: string, totalClass: string) => {
      return `<span class="${currentClass}"></span>/<span class="${totalClass}"></span>`;
    },
  };

  const setSwiper = (instance: any) => {
    swiperRef.current = instance;
  };

  return (
    <div className={cn(styles.ImageCarousel, className)} style={{ width: width }}>
      <Swiper
        onSwiper={setSwiper}
        pagination={(fraction as unknown) as PaginationOptions}
        navigation
        // loop
        thumbs={{ swiper: thumbnailSwiper }}>
        {images.map((image: string) => (
          <SwiperSlide key={image} style={{ height: height }}>
            <Image
              className={styles.ImageCarousel__Image}
              src={image}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </SwiperSlide>
        ))}
        <div className={styles.ImageCarousel__Arrows}>
          <div
            onClick={() => (swiperRef.current as any)?.slidePrev()}
            className={styles.ImageCarousel__ArrowsPrev}>
            <Icon icon="chevron-left" size={20} color={COLORS.WHITE} />
          </div>
          <div
            onClick={() => (swiperRef.current as any)?.slideNext()}
            className={styles.ImageCarousel__ArrowsNext}>
            <Icon icon="chevron-right" size={20} color={COLORS.WHITE} />
          </div>
        </div>
        {pagination && (
          <div className={styles.ImageCarousel__Pagination}>
            <div className="swiper-pagination note" />
          </div>
        )}
      </Swiper>
      {thumbnails && (
        <Swiper
          onSwiper={setThumbnailSwiper}
          spaceBetween={10}
          slidesPerView={3.5}
          freeMode
          watchSlidesVisibility
          watchSlidesProgress
          className="mt-xs">
          {images.map((thumbnail: string) => (
            <SwiperSlide key={thumbnail}>
              <div className={styles.ImageCarousel__ThumbnailWrapper}>
                <Image
                  className={styles.ImageCarousel__Thumbnail}
                  src={thumbnail}
                  objectFit="cover"
                  layout="fill"
                  objectPosition="center"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
