import axios from 'axios';

export const mail = (
  to: string,
  from: string,
  subject: string,
  dynamicTemplateData: { title: string; body: string },
) => {
  return axios.post('/api/mail', { to, from, subject, dynamicTemplateData });
};
