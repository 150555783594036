import COMMON from './common';
import COMPONENTS from './components';
import DOMAIN from './domain';
import FORMS from './forms';
import GLOBALS from './globals.json';

const data = {
  translation: {
    DOMAIN,
    COMMON,
    COMPONENTS,
    GLOBALS,
    FORMS,
  },
};

export default data;
