import cn from 'classnames';
import Image from 'next/image';

import { Button } from '@Components/ui';

import styles from './Banner.module.scss';
import { BannerProps } from './Banner.props';

export const Banner = ({ backgroundColor, image, linkLabel, linkUrl, children }: BannerProps) => {
  return (
    <div className={cn(styles.Banner, `background-${backgroundColor}`)}>
      <div className="container">
        <div className={styles.Banner__CTA}>
          {children}
          <Button href={linkUrl}>{linkLabel}</Button>
        </div>

        <div className={styles.Banner__Image}>
          <Image
            src={image.cdn_files[0].full}
            objectFit="contain"
            layout="fill"
            objectPosition="left bottom"
          />
        </div>
      </div>

      <div className={styles.Banner__Background} />
    </div>
  );
};
