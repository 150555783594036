import cn from 'classnames';

import styles from './FormRow.module.scss';
import { FormRowProps } from './FormRow.props';

export const FormRow = ({ children, className, ...props }: FormRowProps) => {
  return (
    <div className={cn(styles.FormRow, className)} {...props}>
      {children}
    </div>
  );
};
