import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import styles from './ImageGrid.module.scss';
import { ImageGridItemProps, ImageGridProps } from './ImageGrid.props';

const ImageGridItem = ({ image }: ImageGridItemProps) => {
  const imageItem = (
    <div className={styles.ImageGrid__Image}>
      <Image
        src={image.cdn_files[0].full}
        layout="fixed"
        width={155}
        height={100}
        objectFit="contain"
        objectPosition="center"
      />
    </div>
  );

  if (image.author) {
    return (
      <Link href={image.author}>
        <a
          href={image.author}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.ImageGrid__Item}>
          {imageItem}
        </a>
      </Link>
    );
  }

  return <div className={styles.ImageGrid__Item}>{imageItem}</div>;
};

export const ImageGrid = ({ className, images }: ImageGridProps) => {
  return (
    <div className={cn(styles.ImageGrid, className)}>
      {images.map((image, index: number) => (
        <ImageGridItem key={index} image={image} />
      ))}
    </div>
  );
};
