import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextAreaInputProps } from './TextAreaInput.props';

export const TextAreaInput = ({ name, placeholder, ...props }: TextAreaInputProps) => {
  const { register } = useFormContext();
  const { t } = useTranslation();

  return (
    <textarea
      id={name}
      placeholder={placeholder || t('GLOBALS.DEFAULT_PLACEHOLDER')}
      {...(name ? register(name) : null)}
      {...props}
    />
  );
};
