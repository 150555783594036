import axios from 'axios';
import { serialize } from 'object-to-formdata';

import { RegisterResidentFormValues } from '@Components/forms';
import { USER_TYPE } from '@Constants/enums';

export const BackendClient = axios.create({ baseURL: '/api' });

export interface ResidentSubscribeRequest {
  agree_newsletter: false;
  agree_terms: true;
  city: 'wefwef';
  email: 'asd@ff.nl';
  house_number: 'wefewfew';
  name: 'wefewf';
  password: 'Test1234!';
  password_repeat: 'Test1234!';
  phone_number: 'wefwef';
  postal_code: 'wefwef';
  street: 'fewewf';
}

export module SubscriptionModule {
  export const subscribeResident = async (values: RegisterResidentFormValues) => {
    return BackendClient.post('/subscribe', {
      ...values,
      type: USER_TYPE.RESIDENT,
    });
  };
}

export module ContentModule {
  export const upload = async (vacancy: any, token: string) => {
    const formData = serialize(vacancy, {
      indices: true,
    });

    const result = await BackendClient.post('/content/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Firebase-Token': token,
      },
    });

    return result.data;
  };
}

export module AuctionPaymentModule {
  export const checkPayment = async (paymentId: string) => {
    const result = await BackendClient.post('/auction/check_payment', { paymentId });

    return result.data;
  };

  export const pay = async (email: string, preprId: string) => {
    const result = await BackendClient.post('/auction/pay', { email, preprId });

    return result.data;
  };
}
