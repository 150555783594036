import cn from 'classnames';
import Image from 'next/image';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './CompanyImageHeader.module.scss';
import { CompanyImageHeaderItemProps, CompanyImageHeaderProps } from './CompanyImageHeader.props';

const CompanyImageHeaderItem = ({ image }: CompanyImageHeaderItemProps) => {
  return (
    <div className={styles.CompanyImageHeader__Item} key={image.cdn_files[0].full}>
      <Image
        className={styles.CompanyImageHeader__ItemImage}
        src={image.cdn_files[0].full}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
    </div>
  );
};

export const CompanyImageHeader = ({ className, company }: CompanyImageHeaderProps) => {
  const swiperRef = useRef<any>(null);

  const setSwiper = (instance: any) => {
    swiperRef.current = instance;
  };

  if (company.images) {
    return (
      <div className={cn(className)}>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1.1}
          spaceBetween={10}
          className={styles.CompanyImageHeader}
          breakpoints={{
            '640': {
              slidesPerView: 1.5,
            },
            '1024': {
              slidesPerView: 2,
              spaceBetween: 10,
              allowTouchMove: false,
            },
          }}>
          {company.images.map((image) => {
            if (image) {
              return (
                <SwiperSlide key={image.cdn_files[0].full}>
                  <CompanyImageHeaderItem image={image} />
                </SwiperSlide>
              );
            }
            return null;
          })}
        </Swiper>
      </div>
    );
  }

  return <></>;
};
