import AUCTION_FORM from './AuctionForm.json';
import CONTACT_FORM from './ContactForm.json';
import NEWSLETTER_FORM from './NewsletterForm.json';
import REGISTER_COMPANY_FORM from './RegisterCompanyForm.json';
import REGISTER_RESIDENT_FORM from './RegisterResidentForm.json';

export default {
  CONTACT_FORM,
  NEWSLETTER_FORM,
  REGISTER_COMPANY_FORM,
  REGISTER_RESIDENT_FORM,
  AUCTION_FORM,
};
