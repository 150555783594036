import cn from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { Button } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './AuctionItem.module.scss';
import { AuctionItemProps } from './AuctionItem.props';

export const AuctionItem = ({
  label,
  title,
  description,
  price,
  address,
  phone,
  website,
  email,
  image,
  available = false,
  onBuy,
  className,
}: AuctionItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.AuctionItem, 'row', className)}>
      <div className={cn('col-md-7', styles.TextContent)}>
        {!!label && <p className={styles.Label}>{label}</p>}
        {!!title && <h3 className={cn(styles.Title, 'h2')}>{title}</h3>}
        {!!description && (
          <div className={styles.Description} dangerouslySetInnerHTML={{ __html: description }} />
        )}

        {!!price && (
          <p className={cn(styles.Price, !available && styles.IsDisabled)}>
            {price?.toLocaleString(undefined, {
              style: 'currency',
              currency: 'EUR',
              useGrouping: false,
            })}
          </p>
        )}

        <div className={styles.Details}>
          {!!address && (
            <div className={styles.Address} dangerouslySetInnerHTML={{ __html: address }} />
          )}
          <div className={styles.ContactContainer}>
            {!!phone && <a href={`tel:${phone}`}>{phone}</a>}
            {!!website && (
              <a href={website.startsWith('http') ? website : `https://${website}`}>{website}</a>
            )}
            {!!email && <a href={`mailTo:${email}`}>{email}</a>}
          </div>
        </div>
      </div>
      <div className={cn('col-md-5', styles.ImageContainer)}>
        {!!image && (
          <Image src={image.cdn_files?.[0].full} layout="fill" className={cn(styles.Image)} />
        )}
        {!available && <div className={styles.DisabledOverlay} />}
        <Button
          onClick={() => available && onBuy()}
          color={available ? COLORS.PRIMARY : COLORS.LIGHT_GRAY}
          className={styles.Button}>
          {available ? t('COMPONENTS.AUCTION_ITEM.BUY') : t('COMPONENTS.AUCTION_ITEM.SOLD')}
        </Button>
      </div>
    </div>
  );
};
