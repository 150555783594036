/* eslint-disable quote-props */
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

import styles from './SearchFieldAll.module.scss';
import { SearchFieldAllProps } from './SearchFieldAll.props';

export const SearchFieldAll = ({
  className,
  placeholder,
  onChange,
  onEnter,
}: SearchFieldAllProps) => {
  return (
    <div className={cn(styles.SearchField, className)}>
      <button type="submit" className={cn(styles.SearchField__Button, className)}>
        <FontAwesomeIcon
          className={cn(styles.SearchField__Icon, className)}
          icon={faSearch}
          size="xs"
        />
      </button>

      <input
        style={{ background: 'transparent' }}
        id="SearchField"
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        onKeyPress={(e) => {
          onEnter && e.key === 'Enter' && onEnter();
        }}
        placeholder={placeholder}
        type="text"
        className={cn(styles.SearchField__Input, className)}
      />
    </div>
  );
};
