import ABOUTPAGE from './Aboutpage.json';
import AUTHENTICATION from './Authentication.json';
import BLOG from './Blog.json';
import COMPANY from './Company.json';
import COMPANY_PAGE from './CompanyPage.json';
import CONTACTPAGE from './Contactpage.json';
import COOKIES from './Cookie.json';
import DEAL from './Deal.json';
import EDUCATION from './Education.json';
import EVENT from './Event.json';
import GIVEAWAY from './Giveaway.json';
import NEWS from './News.json';
import NOT_FOUND from './NotFound.json';
import PAGE from './Page.json';
import REGISTER from './Register.json';
import REGISTER_PAGE from './RegisterPage.json';
import SIGNUP from './Signup.json';
import THANK_YOU_ORDER_PAGE from './ThankYouOrderPage.json';
import THANK_YOU_PAGE from './ThankYouPage.json';
import VACANCY from './Vacancy.json';

export default {
  NOT_FOUND,
  PAGE,
  SIGNUP,
  THANK_YOU_PAGE,
  EVENT,
  EDUCATION,
  BLOG,
  REGISTER,
  DEAL,
  GIVEAWAY,
  COMPANY_PAGE,
  NEWS,
  THANK_YOU_ORDER_PAGE,
  VACANCY,
  COMPANY,
  CONTACTPAGE,
  REGISTER_PAGE,
  ABOUTPAGE,
  AUTHENTICATION,
  COOKIES,
};
