import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterAccordion } from '@Components/ui';
import { formatString } from '@Utils/index';

import styles from './FilterSidebar.module.scss';
import { FilterSidebarProps } from './FilterSidebar.props';

export const FilterSidebar = ({
  title,
  filters,
  onChange,
  children,
  className,
  initialValues,
  initialSearch,
  extraFilters,
}: FilterSidebarProps) => {
  const { t } = useTranslation();

  const [reRender, setReRender] = useState('');
  const [filter, setFilter] = useState({ branche: [] });
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (Object.values(filter || {}).length > 0 && onChange) {
      if (!reRender) {
        setSearch(initialSearch || '');
      }
      onChange({
        filter,
        search,
      });
    }
  }, [filter, search]);

  function doSearch(searchTerm: string): void {
    if (searchTerm.length !== 0) {
      setSearch(searchTerm);
      setReRender(Math.random().toString());
    } else {
      setSearch('');
    }
  }

  return (
    <div className={cn(styles.FilterSidebar, className)}>
      <h2
        className={styles.FilterSidebar__Title}
        dangerouslySetInnerHTML={{ __html: formatString(title) }}
      />

      <div className={styles.FilterSidebar__Description}>{children}</div>

      <div className={styles.FilterSidebar__Filter}>
        <div className={styles.FilterSidebar__FilterSearch}>
          <strong className={styles.FilterSidebar__SearchTitle}>
            {t('COMPONENTS.FILTER_SIDEBAR.SEARCH_TITLE')}
          </strong>

          <div style={{ position: 'relative' }}>
            <input
              id="filterSearchInput"
              style={{
                background: 'transparent',
                paddingLeft: 0,
                borderRadius: 0,
                borderBottom: 'solid 1px',
              }}
              className={styles.FilterSidebar__Search}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') {
                  doSearch(e.target.value);
                }
              }}
              placeholder={'Zoek op trefwoord'}
              type="text"
            />
            <i
              className="color-black fa-search far"
              style={{
                position: 'absolute',
                inset: '50% 10px auto auto',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
              onClick={() => {
                const searchTerm = document.getElementById('filterSearchInput') as HTMLInputElement;
                doSearch(searchTerm?.value);
              }}
            />
          </div>
        </div>
        {extraFilters && (
          <div className={styles.FilterSidebar__FilterAccordion}>
            <FilterAccordion
              key={reRender}
              initialValues={reRender.length === 0 ? initialValues : filter}
              items={filters}
              useCheckboxes
              onChange={(values: any) => {
                setFilter(values);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
