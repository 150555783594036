import { useEffect, useRef, useState } from 'react';

import { __CLIENT_SIDE__ } from '@Constants/index';

export const useFileURL = (file?: Blob) => {
  const [fileUrl, setFileUrl] = useState('');
  const fileReader = useRef(__CLIENT_SIDE__ ? new FileReader() : null);

  const handleLoadEnd = () => {
    if (fileReader && typeof fileReader.current?.result === 'string') {
      setFileUrl(fileReader.current?.result);
    }
  };

  useEffect(() => {
    fileReader.current?.addEventListener('load', handleLoadEnd);

    return () => {
      fileReader.current?.removeEventListener('load', handleLoadEnd);
    };
  }, []);

  useEffect(() => {
    if (!fileReader) {
      return;
    }

    if (!file) {
      setFileUrl('');
      return;
    }

    if (file) {
      fileReader.current?.readAsDataURL(file);
    }
  }, [file]);

  return fileUrl;
};
