import { useTranslation } from 'react-i18next';

import { CategoryCard } from '../CategoryCard/CategoryCard';
import { CompanyCategoryCardProps } from './CompanyCategoryCard.props';

export const CompanyCategoryCard = ({ company, className }: CompanyCategoryCardProps) => {
  const { t } = useTranslation();

  return (
    <CategoryCard
      className={className}
      href={`${t('COMMON.ROUTES.COMPANIES')}/${company._slug}`}
      title={company.title}
      tags={company.branche || []}
      thumbnailSrc={company.images ? company.images[0].cdn_files[0].full : ''}>
      {company.address}
    </CategoryCard>
  );
};
