export enum COLORS {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  YELLOW = 'yellow',
  BLACK = 'black',
  GRAY = 'gray',
  LIGHT_GRAY = 'lightGray',
  LIGHTER_GRAY = 'lighterGray',
  DARK_GRAY = 'darkGray',
  WHITE = 'white',
  PURPLE = 'purple',
  PINK = 'pink',
  EXTEREME_PINK = 'pink-light',
  ORANGE = 'orange',
  BLUE = 'blue',
  GREEN = 'green',
  TRANSPARENT = 'transparent',
  SUPPORT_LIGHT = 'support-light',
  BORDER = 'border',
}

export enum USER_TYPE {
  RESIDENT = 'RESIDENT',
  COMPANY = 'COMPANY',
}

export enum FILTER_STATE {
  CLOSED = 'CLOSED',
  SEARCH = 'SEARCH',
  FILTERS = 'FILTERS',
}

export enum ORDER_TYPE {
  MEMBER = 'member',
  AUCTION = 'auction',
}
