import cn from 'classnames';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Icon } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './CategoryItemCarousel.module.scss';
import { CategoryItemCarouselProps } from './CategoryItemCarousel.props';

export const CategoryItemCarousel = ({
  className,
  title,
  tintColor,
  slidesPerView,
  children,
}: CategoryItemCarouselProps) => {
  const swiperRef = useRef<any>(null);

  const setSwiper = (instance: any) => {
    swiperRef.current = instance;
  };

  return (
    <div className={cn(styles.CategoryItemCarousel, className)}>
      <div className={styles.CategoryItemCarousel__Header}>
        <h2
          className={cn(styles.CategoryItemCarousel__Title, `color-${tintColor}`)}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        {children.length > slidesPerView && (
          <>
            <div
              onClick={() => (swiperRef.current as any)?.slidePrev()}
              className={styles.CategoryItemCarousel__Prev}>
              <Icon icon="chevron-left" size={10} color={COLORS.WHITE} />
            </div>

            <div
              onClick={() => (swiperRef.current as any)?.slideNext()}
              className={styles.CategoryItemCarousel__Next}>
              <Icon icon="chevron-right" size={10} color={COLORS.WHITE} />
            </div>
          </>
        )}
      </div>

      <div className={styles.CategoryItemCarousel__Slider}>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1.5}
          spaceBetween={10}
          className="m-0"
          breakpoints={{
            '640': {
              slidesPerView: 2,
              spaceBetween: 20,
            },

            '1024': {
              slidesPerView: slidesPerView,
              spaceBetween: 20,
            },
          }}>
          {children.map((child: JSX.Element) => (
            <SwiperSlide key={child.key} className="p-2">
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
