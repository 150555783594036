import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { IconLink } from '@Components/ui';

import styles from './PromotionBlock.module.scss';
import { PromotionBlockProps } from './PromotionBlock.props';

export const PromotionBlock = ({
  className,
  thumbnailUri,
  title,
  bodyTitle,
  linkLabel,
  linkUrl,
  color,
  children,
}: PromotionBlockProps) => {
  return (
    <div className={cn(styles.PromotionBlock, className)}>
      <h2 className={cn(styles.PromotionBlock__Title, `color-${color}`)}>{title}</h2>

      <Link href={linkUrl}>
        <a href={linkUrl} className={styles.PromotionBlock__Image}>
          <Image src={thumbnailUri} layout="fill" objectFit="cover" objectPosition="center" />
        </a>
      </Link>

      <h3>{bodyTitle}</h3>
      <div>{children}</div>

      <IconLink iconColor={color} href={linkUrl} className={`color-${color}`}>
        {linkLabel}
      </IconLink>
    </div>
  );
};
