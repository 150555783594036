import Head from 'next/head';
import React from 'react';

import Props from './Meta.props';

export const Meta = ({ title, description, image, id }: Props) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta name="title" property="og:title" content={title} />
      <meta property="og:description" name="description" content={description?.substr(3, 250)} />
      <meta name="og:type" content="website" />

      <meta property="og:image" name="og:image" content={image} />
      {!!image && <meta name="og:image:secure_url" content={image} />}
      {!!image && <meta name="og:image:width" content="650" />}
      {!!image && <meta name="og:image:height" content="300" />}
      {image && <meta name="twitter:image" content={image} />}

      <meta name="twitter:card" content={image ? 'summary_large_image' : 'summary'} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />

      {/*   For capturing View event Pixel https://docs.prepr.io/optimization-and-personalization/event-data/ */}
      <meta property="prepr:id" content={id} />
    </Head>
  );
};
