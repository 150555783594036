import cn from 'classnames';

import { COLORS } from '@Constants/enums';

import styles from './ProgressBar.module.scss';
import { ProgressBarProps } from './ProgressBar.props';

export const ProgressBar = ({ className, progress, color = COLORS.PRIMARY }: ProgressBarProps) => {
  if (progress > 1 || progress < 0) {
    throw new Error('Progress should bee between 0 and 1');
  }

  return (
    <div className={cn(styles.ProgressBar, className)}>
      <div
        className={cn(styles.ProgressBar__Progress, `background-${color}`)}
        style={{
          width: `${progress * 100}%`,
        }}
      />
    </div>
  );
};
