import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Tag } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './TagCloudInput.module.scss';
import { TagCloudInputProps } from './TagCloudInput.props';

export const TagCloudInput = ({
  name,
  className,
  options = [],
  color = COLORS.GRAY,
  activeColor = COLORS.PRIMARY,
  borderColor = COLORS.TRANSPARENT,
  textColor = COLORS.GRAY,
  activeTextColor = COLORS.WHITE,
}: TagCloudInputProps) => {
  const { register, watch } = useFormContext();

  const values = watch(name) || [];

  return (
    <div className={cn(styles.TagCloudInput, className)}>
      {options.map(({ key, value }) => {
        const isActive = values.includes(String(key));

        return (
          <div className={styles.TagCloudInput__Input} key={`${name}-${key}-${Math.random()}`}>
            <input value={String(key)} type="checkbox" id={`${name}-${key}`} {...register(name)} />

            <label className={styles.TagCloudInput__Tag} htmlFor={`${name}-${key}`}>
              <Tag
                backgroundColor={isActive && activeColor ? activeColor : color}
                borderColor={borderColor}
                textColor={isActive && activeTextColor ? activeTextColor : textColor}>
                {value}
              </Tag>
            </label>
          </div>
        );
      })}
    </div>
  );
};
