import ALT_SEARCH_INPUT from './AltSearchInput.json';
import ARCHIVE_PAGE from './ArchivePage.json';
import AUCTION_ITEM from './AuctionItem.json';
import AUCTION_LIST from './AuctionList.json';
import COMPANY_CONTACT_DETAIL_CARD from './CompanyContactDetailCard.json';
import DROPDOWN_TAG from './DropdownTag.json';
import FILE_INPUT from './FileInput.json';
import FILTER_SIDEBAR from './FilterSidebar.json';
import HEADER from './Header.json';
import PAYWALL_BLOCK from './PaywallBlock.json';
import PROMOTION_BANNER from './PromotionBanner.json';
import SEARCH_BLOCK from './SearchBlock.json';
import USP_BLOCK from './USPBlock.json';
import VEELGESTELDE_VRAGEN from './VeelgesteldeVragen.json';

export default {
  AUCTION_LIST,
  AUCTION_ITEM,
  VEELGESTELDE_VRAGEN,
  DROPDOWN_TAG,
  PAYWALL_BLOCK,
  ARCHIVE_PAGE,
  SEARCH_BLOCK,
  PROMOTION_BANNER,
  USP_BLOCK,
  HEADER,
  FILTER_SIDEBAR,
  ALT_SEARCH_INPUT,
  FILE_INPUT,
  COMPANY_CONTACT_DETAIL_CARD,
};
