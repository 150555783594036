import { captureException } from '@sentry/minimal';
import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { mail } from '@Utils/MailUtils';
import { formatString } from '@Utils/StringUtils';
import Yup from '@Utils/YupUtils';

import styles from './RequestCompanyAccessForm.module.scss';
import {
  RequestCompanyAccessFormData,
  RequestCompanyAccessFormProps,
} from './RequestCompanyAccessForm.props';

export const RequestCompanyAccessForm = ({
  className,
  title = true,
}: RequestCompanyAccessFormProps) => {
  const { t } = useTranslation();
  const [hasSent, setHasSent] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    company: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().email().required(),
  });

  const onSubmit = async (data: RequestCompanyAccessFormData) => {
    try {
      const body = `
        <table>
        <tr>
          <td>
            <strong>Bedrijfsnaam</strong>
            <div>${data.company || '-'}</div>
          </td>
        </tr>

        <tr>
          <td>
            <strong>Contactpersoon</strong>
            <div>${data.name}</div>
          </td>
        </tr>
  
          <tr>
            <td>
              <strong>E-mailadres</strong>
              <div><a href="mailto:${data.email}">${data.email}</a></div>
            </td>
          </tr>
  
          <tr>
            <td>
              <strong>Telefoonnummer</strong>
              <div><a href="tel:${data.phone}">${data.phone}</a></div>
            </td>
          </tr>
  
          <tr>
            <td>
              <strong>Extra toelichting</strong>
              <div>${data.message}</div>
            </td>
          </tr>
        </table>
      `;

      await mail(
        String(process.env.NEXT_PUBLIC_EMAIL_ADMIN),
        String(process.env.NEXT_PUBLIC_EMAIL_FROM),
        t('FORMS.REGISTER_COMPANY_FORM.EMAIL_SUBJECT'),

        {
          title: t('FORMS.REGISTER_COMPANY_FORM.EMAIL_TITLE'),
          body: body,
        },
      );

      setHasSent(true);
    } catch (e) {
      captureException(e);
      alert(t('GLOBALS.ERRORS.GENERIC'));
    }
  };

  return (
    <div className={cn(styles.RequestCompanyAccessForm, className)}>
      {title && (
        <h2
          className="mb-lg"
          dangerouslySetInnerHTML={{
            __html: formatString(t('FORMS.REGISTER_COMPANY_FORM.TITLE')),
          }}
        />
      )}
      {hasSent && (
        <div dangerouslySetInnerHTML={{ __html: t('FORMS.REGISTER_COMPANY_FORM.SUCCESS') }} />
      )}

      {!hasSent && (
        <Form.Form id="registercompanyform" validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form.Row>
            <Form.Field label={t('FORMS.REGISTER_COMPANY_FORM.COMPANY_LABEL')} isRequired={true}>
              <Form.TextInput name="company" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>

            <Form.Field label={t('FORMS.REGISTER_COMPANY_FORM.NAME_LABEL')} isRequired={true}>
              <Form.TextInput name="name" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>
          </Form.Row>

          <Form.Row>
            <Form.Field label={t('FORMS.REGISTER_COMPANY_FORM.EMAIL_LABEL')} isRequired={true}>
              <Form.TextInput name="email" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>

            <Form.Field label={t('FORMS.REGISTER_COMPANY_FORM.PHONE_LABEL')} isRequired={true}>
              <Form.TextInput name="phone" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>
          </Form.Row>

          <Form.Field label={t('FORMS.REGISTER_COMPANY_FORM.MESSAGE_LABEL')}>
            <Form.TextAreaInput name="message" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
          </Form.Field>

          <div className="d-flex justify-content-end">
            <Button id="registercompanybtn" color={COLORS.GREEN} type="submit">
              {t('GLOBALS.DEFAULT_SUBMIT')}
            </Button>
          </div>
        </Form.Form>
      )}
    </div>
  );
};
