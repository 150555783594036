import { useFormContext } from 'react-hook-form';

import { Icon } from '@Components/ui';

import styles from './SelectInput.module.scss';
import { SelectInputProps } from './SelectInput.props';

export const SelectInput = ({ options = [], name, ...props }: SelectInputProps) => {
  const { register } = useFormContext();

  return (
    <div className={styles.SelectInput}>
      <select id={name} {...register(String(name))} {...props}>
        {options.map((item) => {
          return (
            <option key={`${name}-${item.value}`} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>

      <Icon icon="chevron-down" type="light" size={10} className={styles.SelectInput__Icon} />
    </div>
  );
};
