import cn from 'classnames';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { Icon } from '@Components/ui/Icon/Icon';
import { COLORS } from '@Constants/enums';

import styles from './Accordion.module.scss';
import { AccordionItemProps, AccordionProps, IAccordionItem } from './Accordion.props';

const AccordionItem = ({ title, children, active, onClick }: AccordionItemProps) => {
  return (
    <div
      data-testid="AccordionItem"
      className={cn(styles.Accordion__Item, {
        [styles['AccordionItem--Active']]: active,
      })}>
      <div className={styles.Accordion__ItemHeader} onClick={onClick}>
        <Icon
          className={styles.Accordion__ItemIcon}
          icon={active ? 'minus' : 'plus'}
          color={COLORS.ORANGE}
          type="regular"
        />

        <div className={styles.Accordion__ItemTitle}>{title}</div>
      </div>
      <AnimateHeight
        className={cn(styles.Accordion__ItemChildren, 'note')}
        height={active ? 'auto' : 0}>
        {children}
      </AnimateHeight>
      <hr className={styles.Accordion__Divider} />
    </div>
  );
};

export const Accordion = ({ className, items }: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const itemClicked = (key: number) => {
    if (activeIndex === key) {
      setActiveIndex(null);
    } else {
      setActiveIndex(key);
    }
  };

  return (
    <div className={cn(styles.Accordion, className)}>
      {items.map((item: IAccordionItem, index: number) => (
        <AccordionItem
          key={index}
          active={activeIndex === index}
          onClick={() => itemClicked(index)}
          {...item}
        />
      ))}
    </div>
  );
};
