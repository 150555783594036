import cn from 'classnames';
import { useRouter } from 'next/router';

import { Icon } from '@Components/ui';

import styles from './BackButton.module.scss';
import { BackButtonProps } from './BackButton.props';

export const BackButton = ({ className }: BackButtonProps) => {
  const router = useRouter();

  return (
    <div className={cn(styles.BackButton, className)} onClick={() => router.back()}>
      <Icon icon="long-arrow-left" type="light" size={30} />
    </div>
  );
};
