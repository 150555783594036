import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import styles from './CheckboxInput.module.scss';
import { CheckboxInputProps } from './CheckboxInput.props';

export const CheckboxInput = ({
  options = [],
  name,
  className,
  labelClassName,
}: CheckboxInputProps) => {
  const { register } = useFormContext();

  return (
    <div className={cn(styles.CheckboxInput, className)}>
      {options.map((item) => {
        return (
          <div key={`${name}-${item.key}`} className={styles.CheckboxInput__Item}>
            <input
              type="checkbox"
              id={`${name}-${item.key}`}
              value={String(item.key)}
              {...register(String(name))}
            />

            <label
              className={cn(styles.CheckboxInput__Label, labelClassName)}
              htmlFor={`${name}-${item.key}`}>
              {item.value}
            </label>
          </div>
        );
      })}
    </div>
  );
};
