import cn from 'classnames';
import { useEffect } from 'react';

import { Footer, Header } from '@Components/layout';
import { COLORS } from '@Constants/enums';

import styles from './Page.module.scss';
import { PageProps } from './Page.props';

export const Page = ({ page, theme = COLORS.WHITE, children, className }: PageProps) => {
  useEffect(() => {
    const script = document.createElement('script');

    const preprPixlToken = '6690afd068a7693ee2a9be78ec70624f40c58c78690d94b3ad26448d4e9737f0';
    script.innerHTML = `!function (e, t, n, p, o, a, i, s, c) {
      e[o] || ((i = e[o] = function () {
        i.process ? i.process.apply(i, arguments) : i.queue.push(arguments)
      }).queue = [], i.t = 1 * new Date, (s = t.createElement(n)).async = 1, s.src = "https://cdn.tracking.prepr.io/js/openpixel.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (c = t.getElementsByTagName(n)[0]).parentNode.insertBefore(s, c))
    }(window, document, "script", 0, "opix"),
      opix("init", "${preprPixlToken}")`;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={cn(styles.Page, className)} data-theme={theme}>
      <Header {...page} />

      {children}

      <Footer {...page} />
    </div>
  );
};
