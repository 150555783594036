import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFileURL } from '@Hooks/index';

import styles from './FileInput.module.scss';
import { FileInputProps } from './FileInput.props';

export const FileInput = ({ uploadLabel, name, onPreviewChange, ...props }: FileInputProps) => {
  const { register, watch } = useFormContext();
  const { t } = useTranslation();

  const value: FileList | undefined = watch(name);

  const fileUrl = useFileURL(value?.[0]);

  useEffect(() => {
    onPreviewChange && value && onPreviewChange({ filename: value[0].name, src: fileUrl });
  }, [fileUrl]);

  return (
    <div className={styles.FileInput}>
      <div className={styles.FileInput__InputWrapper}>
        <input
          id={name}
          type="file"
          {...props}
          {...register(name)}
          className={styles.FileInput__Input}
        />

        <div className={styles.FileInput__FileName}>
          {value && value[0] ? value[0].name : t('COMPONENTS.FILE_INPUT.PLACEHOLDER')}
        </div>

        <label className={styles.FileInput__Label} htmlFor={name}>
          {uploadLabel || t('COMPONENTS.FILE_INPUT.UPLOAD_LABEL')}
        </label>
      </div>
    </div>
  );
};
