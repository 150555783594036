import { faChevronDown, faCookieBite } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next/';

import { Button } from '@Components/ui';

import styles from './CookieDialog.module.scss';
import { CookieDialogProps } from './CookieDialog.props';

interface CookieConsentOption {
  id: string;
  name: string;
  checked: boolean;
  visible: boolean;
  disabled?: boolean;
  desc: string;
}

export const CookieDialog = ({ className }: CookieDialogProps) => {
  const { t } = useTranslation();
  const [, setConsent] = useCookies(['CookieConsent']);
  const [visible, setVisible] = useState(false);
  const [cookies, setCookies] = useState<CookieConsentOption[]>([
    {
      id: 'NECESSARY',
      name: t('DOMAIN.COOKIES.OPTIONS.NECESSARY.TITLE'),
      checked: true,
      visible: false,
      disabled: true,
      desc: t('DOMAIN.COOKIES.OPTIONS.NECESSARY.DESCRIPTION'),
    },

    {
      id: 'PREFERENCES',
      name: t('DOMAIN.COOKIES.OPTIONS.PREFERENCES.TITLE'),
      checked: true,
      visible: false,
      desc: t('DOMAIN.COOKIES.OPTIONS.PREFERENCES.DESCRIPTION'),
    },

    {
      id: 'STATISTICS',
      name: t('DOMAIN.COOKIES.OPTIONS.STATISTICS.TITLE'),
      checked: true,
      visible: false,
      desc: t('DOMAIN.COOKIES.OPTIONS.STATISTICS.DESCRIPTION'),
    },

    {
      id: 'MARKETING',
      name: t('DOMAIN.COOKIES.OPTIONS.MARKETING.TITLE'),
      checked: true,
      visible: false,
      desc: t('DOMAIN.COOKIES.OPTIONS.MARKETING.DESCRIPTION'),
    },
  ]);

  const toggleCookies = () => {
    setVisible((v) => !v);
  };

  const toggleCookie = (cookie: CookieConsentOption) => {
    if (!cookie.disabled) {
      const newCookies = [...cookies];
      const item = newCookies.find((c: CookieConsentOption) => c.id === cookie.id);

      if (item) item.checked = !item.checked;

      setCookies(newCookies);
    }
  };

  const toggleCookieSummary = (cookie: CookieConsentOption) => {
    const newCookies = [...cookies];
    const item = newCookies.find((c: CookieConsentOption) => c.id === cookie.id);

    if (item) item.visible = !item.visible;

    setCookies(newCookies);
  };

  const getCookies = () => (
    <div
      className={cn([styles.DialogCookies], {
        [styles.CookiesVisible]: visible,
      })}>
      {cookies.map((cookie) => {
        return (
          <div
            key={cookie.id}
            className={cn([styles.Cookie], {
              [styles.CookieActive]: cookie.visible,
            })}>
            <div className={styles.CookieHeader}>
              <div className={styles.CookieCheckbox}>
                <input
                  type="checkbox"
                  id={cookie.id}
                  value={cookie.id}
                  disabled={cookie.disabled}
                  checked={cookie.checked}
                  onChange={() => {}}
                />

                <label htmlFor={cookie.id} onClick={() => toggleCookie(cookie)} />
              </div>

              <div className={styles.CookieTitle} onClick={() => toggleCookieSummary(cookie)}>
                {cookie.name}
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>

            <div className={styles.CookieSummary}>{cookie.desc}</div>
          </div>
        );
      })}
    </div>
  );

  const confirm = () => {
    const myCookies: { [key: string]: boolean } = {};

    cookies.forEach((cookie) => {
      myCookies[cookie.id] = cookie.checked;
    });

    const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    setConsent('CookieConsent', JSON.stringify(myCookies), { expires: date });
  };

  /**
   * The user has not accepted the CookieConsent yet, so we should show the
   * popup with all the cookie's information.
   */
  return (
    <div className={cn(styles.CookieDialog, className)} role="alertdialog">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className={styles.DialogTitle}>
              <FontAwesomeIcon icon={faCookieBite} />
              {t('DOMAIN.COOKIES.COOKIES_ON_SITE', { siteName: process.env.SITE_NAME })}
            </h4>

            <div className={styles.DialogContent}>{`${t('DOMAIN.COOKIES.MESSAGE')} `}</div>

            {getCookies()}

            <div className={styles.DialogButtons}>
              <Button className={styles.DialogButton} onClick={toggleCookies}>
                {t('DOMAIN.COOKIES.CHANGE_SETTINGS')}
              </Button>

              <Button className={styles.DialogButton} onClick={confirm}>
                {t('DOMAIN.COOKIES.ACCEPT')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
