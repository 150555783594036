/**
 * Replaces **Lipsum** with <strong>Lipsum</strong> so you can
 * make fonts bold from any custom text string.
 *
 * @param value string
 * @returns string
 */
export const formatString = (value: string): string => {
  return value.replace(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>');
};

export const excerpt = (value: string, length = 200) => {
  const stripped = value.replace(/(<([^>]+)>)/gi, '');

  if (stripped.length > length) {
    return stripped.substring(0, length) + '...';
  }

  return stripped;
};

export const getButtonID = (value: string) => {
  const slugify = value
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return `Button-${slugify}`;
};
