import { useTranslation } from 'react-i18next';

import { BaseCard } from '@Components/ui';
import { CircledIconButton } from '@Components/ui/CircledIconButton/CircledIconButton';
import { formatString } from '@Utils/StringUtils';

import styles from './CompanyContactDetailCard.module.scss';
import { CompanyContactDetailCardProps } from './CompanyContactDetailCard.props';

export const CompanyContactDetailCard = ({ className, company }: CompanyContactDetailCardProps) => {
  const { t } = useTranslation();

  const hasAddress = company.address || company.postal_code || company.city;
  const hasContact = company.phone || company.emailaddress;
  const hasWebsite = company.website_url;
  const hasSocials =
    company.facebook_url || company.whatsapp_url || company.linkedin_url || company.instagram_url;

  if (hasAddress || hasContact || hasWebsite || hasSocials) {
    return (
      <div className={className}>
        <BaseCard>
          <h2
            className={styles.CompanyContactDetailCard__Title}
            dangerouslySetInnerHTML={{
              __html: formatString(t('COMPONENTS.COMPANY_CONTACT_DETAIL_CARD.TITLE')),
            }}
          />

          {hasAddress && (
            <p className={styles.CompanyContactDetailCard__Paragraph}>
              {company.address && <div>{company.address}</div>}
              {company.postal_code && <div>{company.postal_code}</div>}
              {company.city && <div>{company.city}</div>}
            </p>
          )}

          {hasContact && (
            <p className={styles.CompanyContactDetailCard__Paragraph}>
              {company.phone && (
                <a
                  className="d-block"
                  href={`tel:${company.phone}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {company.phone}
                </a>
              )}

              {company.emailaddress && (
                <a
                  className="d-block"
                  href={`mailto:${company.emailaddress}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {company.emailaddress}
                </a>
              )}
            </p>
          )}

          {hasWebsite && (
            <p className={styles.CompanyContactDetailCard__Paragraph}>
              <a href={company.website_url} target="_blank" rel="noopener noreferrer">
                {company.website_url}
              </a>
            </p>
          )}

          {hasSocials && (
            <span className={styles.CompanyContactDetailCard__Socials}>
              {company.facebook_url && (
                <CircledIconButton
                  target="_blank"
                  rel="noopener noreferrer"
                  icon="facebook"
                  iconType="brands"
                  href={company.facebook_url}
                />
              )}

              {company.instagram_url && (
                <CircledIconButton
                  target="_blank"
                  rel="noopener noreferrer"
                  icon="instagram"
                  iconType="brands"
                  href={company.instagram_url}
                />
              )}

              {company.linkedin_url && (
                <CircledIconButton
                  target="_blank"
                  rel="noopener noreferrer"
                  icon="linkedin"
                  iconType="brands"
                  href={company.linkedin_url}
                />
              )}

              {company.whatsapp_url && (
                <CircledIconButton
                  target="_blank"
                  rel="noopener noreferrer"
                  icon="whatsapp"
                  iconType="brands"
                  href={company.whatsapp_url}
                />
              )}
            </span>
          )}
        </BaseCard>
      </div>
    );
  }

  return <></>;
};
