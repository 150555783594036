import { __CLIENT_SIDE__ } from '@Constants/environment';

export const getInputNameFromElement = (element: JSX.Element): string => {
  if (!element.props.children && element.props.name) {
    return element.props.name;
  }

  if (element.props.children) {
    return getInputNameFromElement(element.props.children);
  }

  return '';
};

export const getInputNameFromChildren = (children: JSX.Element | JSX.Element[]): string => {
  if (!children) {
    return '';
  }

  if (Array.isArray(children)) {
    for (const child of children) {
      const name = getInputNameFromElement(child);

      if (name) {
        return name;
      }
    }

    return '';
  } else {
    return getInputNameFromElement(children);
  }
};

export const scrollTo = (selector: string) => {
  if (!__CLIENT_SIDE__) {
    return;
  }

  const element = document.querySelector(selector);

  if (element) {
    const { top } = element.getBoundingClientRect();
    window.scrollTo({
      top: top + window.pageYOffset - 180,
      behavior: 'smooth',
    });
  }
};
