/* eslint-disable quote-props */
/* eslint-disable prettier/prettier */
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Icon } from '@Components/ui';

import styles from './TagSearchInput.module.scss';
import { TagSearchInputProps } from './TagSearchInput.props';

export const TagSearchInput = ({ onChange, className, value, branches }: TagSearchInputProps) => {
  const { t } = useTranslation();

  const options = branches.map((branch) => {
    return { value: branch.value, label: branch.value };
  });

  const style = {
    control: (base: any) => ({
      ...base,
      height: '100%',
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: '#000',
        opacity: 1,
      };
    },
  };

  return (
    <div className={cn(styles.TagSearchInput, className)}>
      <Select
        options={options}
        id="SearchField"
        className={cn(styles.TagSearchInput__Input, className)}
        placeholder={t('COMPONENTS.ALT_SEARCH_INPUT.PLACEHOLDER')}
        type="text"
        isMulti
        styles={style}
        value={
          value && value.map
            ? value.map((item) => {
                return { label: item, value: item };
              })
            : undefined
        }
        onChange={(item: any) => {
          const items = (item as any[]).map((single) => single.value);
          onChange && onChange({ branche: items });
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />

      <button type="submit" className={styles.TagSearchInput__Icon}>
        <Icon icon="search" size={15} type="regular" />
      </button>
    </div>
  );
};
