import cn from 'classnames';

import { Button } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { formatString, getButtonID } from '@Utils/index';

import styles from './PromotionBanner.module.scss';
import { PromotionBannerProps } from './PromotionBanner.props';

export const PromotionBanner = ({
  title,
  buttonLink,
  buttonTitle,
  newsletterLink,
  newsletterTitle,
  hasBackground,
  className,
}: PromotionBannerProps) => {
  return (
    <div className={cn(styles.PromotionBanner, className)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.PromotionBanner__Wrapper}>
              <h2
                className={styles.PromotionBanner__Title}
                dangerouslySetInnerHTML={{ __html: formatString(title) }}
              />

              <div className="d-flex flex-column">
                {buttonLink && buttonTitle && (
                  <Button
                    id={getButtonID(buttonTitle)}
                    className={cn(styles.PromotionBanner__Button, 'mb-xs')}
                    href={buttonLink}>
                    {buttonTitle}
                  </Button>
                )}

                {newsletterLink && newsletterTitle && (
                  <Button
                    id={getButtonID('nieuwsbrief')}
                    color={COLORS.BLACK}
                    href={newsletterLink}
                    className="mt-md">
                    {newsletterTitle}
                  </Button>
                )}
              </div>

              {hasBackground && <div className={styles.PromotionBanner__Background} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
