import cn from 'classnames';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Icon } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { getInputNameFromChildren } from '@Utils/index';

import styles from './FormField.module.scss';
import { FormFieldProps } from './FormField.props';

export const FormField = ({
  onChange,
  label,
  children,
  isRequired,
  showIcon = true,
  description,
}: FormFieldProps) => {
  const { formState, control } = useFormContext();

  const inputName = getInputNameFromChildren(children);

  const value = useWatch({
    control,
    name: inputName,
  });

  useEffect(() => {
    onChange && onChange(value, control);
  }, [value, control, onChange]);

  const errorMessage = get(formState.errors, inputName)?.message;
  const isTouched = get(formState.touchedFields, inputName);
  const isValid = isTouched && !errorMessage;

  const renderInfo = () => {
    if (errorMessage) {
      return <div className={styles.FormField__ErrorMessage}>{errorMessage}</div>;
    }

    if (description) {
      return <div className={styles.FormField__Description}>{description}</div>;
    }
  };

  return (
    <div
      className={cn(styles.FormField, {
        [styles['FormField--Error']]: !!errorMessage,
        [styles['FormField--Valid']]: isValid,
      })}>
      {label && (
        <label className={styles.FormField__Label} htmlFor={inputName}>
          {label}
          {isRequired && '*'}
        </label>
      )}
      <div className={styles.FormField__InputContainer}>
        {children}

        {showIcon && (
          <>
            {!!errorMessage && (
              <Icon
                className={styles.FormField__Icon}
                icon="info-circle"
                type="light"
                color={COLORS.PRIMARY}
                size={12}
              />
            )}
            {!!isValid && (
              <Icon
                className={styles.FormField__Icon}
                icon="check"
                type="regular"
                color={COLORS.GREEN}
                size={12}
              />
            )}
          </>
        )}
      </div>

      <div className={styles.FormField__InfoText}>{renderInfo()}</div>
    </div>
  );
};
