import cn from 'classnames';
import { createContext, useContext, useState } from 'react';

import { Form, ProgressBar } from '@Components/ui';
import Yup from '@Utils/YupUtils';

import styles from './StepByStepForm.module.scss';
import { StepByStepFormProps } from './StepByStepForm.props';

export interface StepByStepFormContextValue {
  toNextStep: () => void;
  toPrevStep: () => void;
  setStepNumber: (step: number) => void;
  validationSchema?: Yup.AnyObjectSchema;
  canGoBack: boolean;
  canGoNext: boolean;
  error?: string;
}

const StepByStepFormContext = createContext<StepByStepFormContextValue | null>(null);

export const useStepsFormContext = (): StepByStepFormContextValue => {
  const contextValue = useContext(StepByStepFormContext);
  if (!contextValue) {
    throw new Error(
      'There is no context value set. Please make sure that you are in a component that is a child of StepByStepForm.',
    );
  }

  return contextValue;
};

export const StepByStepForm = ({
  className,
  steps,
  validationSchema,
  error,
  ...formProps
}: StepByStepFormProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const progress = currentStep / steps.length;
  const canGoBack = currentStep > 1;
  const canGoNext = currentStep < steps.length;

  const setStepHandler = (step: number) => {
    if (step > steps.length) {
      return console.warn('Max steps limit reached.');
    }

    if (step < 1) {
      return console.warn('Min steps limit reached.');
    }

    setCurrentStep(step);
  };

  const handleNextStep = () => {
    if (canGoNext) {
      setStepHandler(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (canGoBack) {
      setStepHandler(currentStep - 1);
    }
  };

  const contextValues = {
    toNextStep: handleNextStep,
    toPrevStep: handlePrevStep,
    setStepNumber: setStepHandler,
    validationSchema: validationSchema,
    canGoBack,
    canGoNext,
    error,
  };

  return (
    <StepByStepFormContext.Provider value={contextValues}>
      <div className={cn(styles.StepByStepForm, className)}>
        <ProgressBar className={styles.StepByStepForm__Progress} progress={progress} />
        <Form.Form validationSchema={validationSchema} {...formProps}>
          {steps[currentStep - 1]}
        </Form.Form>
      </div>
    </StepByStepFormContext.Provider>
  );
};
