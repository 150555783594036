import i18next from 'i18next';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: i18next.t('COMMON.FORMS.VALIDATION.MIXED.REQUIRED'),
  },
  string: {
    min: i18next.t('COMMON.FORMS.VALIDATION.STRING.MIN'),
    max: i18next.t('COMMON.FORMS.VALIDATION.STRING.MAX'),
    url: i18next.t('COMMON.FORMS.VALIDATION.STRING.URL'),
    email: i18next.t('COMMON.FORMS.VALIDATION.STRING.EMAIL'),
  },
  boolean: {
    required: i18next.t('COMMON.FORMS.VALIDATION.MIXED.REQUIRED.REQUIRED'),
  },
});

export default yup;
