import cn from 'classnames';
import { format } from 'date-fns';
import nl from 'date-fns/locale/nl';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { Icon } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './EventItem.module.scss';
import { EventItemProps } from './EventItem.props';

export const EventItem = ({
  title,
  description,
  image,
  location,
  website,
  date,
  end_date,
  className,
  list,
  _slug,
}: EventItemProps) => {
  const { t } = useTranslation();

  const getCorrectDate = (inputDateString: string) => {
    const inputDate = new Date(inputDateString);
    const dateTimestamp = inputDate.valueOf();
    const TimezoneDifference = inputDate.getTimezoneOffset() * 60 * 1000;

    return new Date(dateTimestamp + TimezoneDifference);
  };

  const eventDate = format(getCorrectDate(date), 'eeee dd MMMM yyyy', { locale: nl });
  const eventStartTime = format(getCorrectDate(date), 'HH:mm', { locale: nl });
  const eventEndTime = format(getCorrectDate(end_date), 'HH:mm', { locale: nl });

  return (
    <a
      href={`${t('COMMON.ROUTES.EVENTS')}/${_slug}`}
      className={cn(styles.EventItem, 'row', className)}>
      <>
        <div className={cn('col-md-7', styles.TextContent)}>
          {!!date && <p className={styles.Label}>{eventDate}</p>}
          {!!title && <h3 className={cn(styles.Title, 'h2')}>{title}</h3>}
          {!!description && (
            <div className={styles.Description} dangerouslySetInnerHTML={{ __html: description }} />
          )}

          <div className={styles.Details}>
            <div className={styles.Info}>
              <Icon
                className={styles.Icon}
                icon={'calendar-alt'}
                type="regular"
                size={10}
                color={COLORS.PRIMARY}
              />
              {t('DOMAIN.EVENT.EVENT_INFORMATION')}
            </div>

            <div className={styles.Location}>
              {!!location && (
                <div className={styles.Address} dangerouslySetInnerHTML={{ __html: location }} />
              )}

              <div className={styles.ContactContainer}>
                {'('}
                {!!website && (
                  <a href={website.startsWith('http') ? website : `https://${website}`}>
                    {website}
                  </a>
                )}
                {')'}
              </div>
            </div>
          </div>
          <div
            className={styles.Time}>{`${eventDate} van ${eventStartTime} - ${eventEndTime}`}</div>
          <span className={styles.Link}>
            {list === 'futureEvents' ? 'Aanmelden' : 'Lees verder'}
            <i className="fa-arrow-right far" />
          </span>
        </div>
        <div className={cn('col-md-5', styles.ImageContainer)}>
          {!!image && (
            <Image
              src={image.cdn_files?.[0].full}
              layout="fill"
              className={cn(styles.Image)}
              alt={title}
            />
          )}
        </div>
      </>
    </a>
  );
};
