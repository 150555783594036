import cn from 'classnames';

import { BaseCard, Tag } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './SingleCard.module.scss';
import { SingleCardProps } from './SingleCard.props';

export const SingleCard = ({
  title,
  categoryTitle,
  categoryColor,
  company,
  tags,
  children,
  className,
}: SingleCardProps) => {
  return (
    <BaseCard
      height="auto"
      className={cn(styles.SingleCard, className)}
      backgroundColor={COLORS.SUPPORT_LIGHT}
      borderColor={COLORS.BORDER}>
      <div className={cn(styles.SingleCard__Category, `background-${categoryColor}`)}>
        {categoryTitle}
      </div>

      <h3>{title}</h3>
      {children}

      <div>
        <p>{company.title}</p>

        <p>
          <a className="d-block" href={`mailto:${company.emailaddress}`}>
            {company.emailaddress}
          </a>

          <a className="d-block" href={`tel:${company.phone}`}>
            {company.phone}
          </a>

          <a
            className="d-block"
            href={company.website_url}
            target="_blank"
            rel="noopener noreferrer">
            {company.website_url}
          </a>
        </p>
      </div>

      <div className={styles.SingleCard__Tags}>
        {tags.map((tag) => {
          return (
            <Tag backgroundColor={COLORS.WHITE} className={styles.SingleCard__Tag}>
              {tag.body}
            </Tag>
          );
        })}
      </div>
    </BaseCard>
  );
};
