import cn from 'classnames';
import { isArray } from 'lodash';
import { useState } from 'react';

import { Form, Icon } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './FilterAccordion.module.scss';
import { FilterAccordionItemProps, FilterAccordionProps } from './FilterAccordion.props';

const FilterAccordionItem = ({
  title,
  name,
  active,
  options,
  onClickExpand,
}: FilterAccordionItemProps) => {
  return (
    <div
      className={cn(styles.FilterAccordionItem, {
        [styles['FilterAccordionItem--Active']]: active,
      })}>
      <div className={styles.FilterAccordionItem__Header} onClick={onClickExpand}>
        {title}
        <Icon icon={active ? 'minus' : 'plus'} />
      </div>

      <div className={styles.FilterAccordionItem__Body}>
        <Form.TagCloudInput
          color={COLORS.TRANSPARENT}
          activeColor={COLORS.BLACK}
          borderColor={COLORS.BLACK}
          textColor={COLORS.BLACK}
          options={options}
          name={name}
        />
      </div>
    </div>
  );
};
const FilterAccordionCheckboxItem = ({
  title,
  name,
  active,
  options,
  onClickExpand,
}: FilterAccordionItemProps) => {
  return (
    <div
      className={cn(styles.FilterAccordionItem, {
        [styles['FilterAccordionItem--Active']]: active,
      })}>
      <div className={styles.FilterAccordionItem__Header} onClick={onClickExpand}>
        {title}
        <Icon icon={active ? 'minus' : 'plus'} />
      </div>

      <div className={styles.FilterAccordionItem__Body}>
        <Form.CheckboxInput
          className={styles.FilterAccordionItem__CheckboxInput}
          labelClassName={styles.FilterAccordionItem__CheckboxLabel}
          options={options}
          name={name}
        />
      </div>
    </div>
  );
};

export const FilterAccordion = ({
  className,
  items,
  useCheckboxes,
  onChange,
  initialValues,
}: FilterAccordionProps) => {
  const [openFilter, setOpenFilter] = useState('');

  const handleChange = (values: any) => {
    const items = Object.keys(values).reduce((obj: any, key) => {
      obj[key] = isArray(values[key]) ? values[key] : [];
      return obj;
    }, {});

    if (onChange && items) {
      onChange(items);
    }
  };

  return (
    <div className={cn(styles.FilterAccordion, className)}>
      <Form.Form initialValues={initialValues} onChange={(values: any) => handleChange(values)}>
        {items.map((item: FilterAccordionItemProps) => (
          <div key={item.name} className={styles.FilterAccordion__Item}>
            {useCheckboxes ? (
              <FilterAccordionCheckboxItem
                {...item}
                active={openFilter === item.name}
                onClickExpand={() => {
                  setOpenFilter(openFilter === item.name ? '' : item.name);
                }}
              />
            ) : (
              <FilterAccordionItem
                {...item}
                active={openFilter === item.name}
                onClickExpand={() => {
                  setOpenFilter(openFilter === item.name ? '' : item.name);
                }}
              />
            )}
          </div>
        ))}
      </Form.Form>
    </div>
  );
};
