import cn from 'classnames';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { IconLink } from '@Components/ui';

import styles from './CategoryPreviewWrapper.module.scss';
import { CategoryPreviewWrapperProps } from './CategoryPreviewWrapper.props';

export const CategoryPreviewWrapper = ({
  className,
  title,
  seeMoreTitle,
  seeMoreHref,
  tintColor,
  children,
}: CategoryPreviewWrapperProps) => {
  return (
    <div className={cn(styles.CategoryPreviewWrapper, className)}>
      <div className={styles.CategoryPreviewWrapper__Header}>
        <h2 className={cn(styles.CategoryPreviewWrapper__Title, `color-${tintColor}`)}>{title}</h2>
        <IconLink
          className={styles.CategoryPreviewWrapper__SeeMoreButton}
          href={seeMoreHref}
          children={seeMoreTitle}
          iconColor={tintColor}
        />
      </div>
      <Swiper
        slidesPerView={1.2}
        spaceBetween={20}
        breakpoints={{
          '640': {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '1024': {
            slidesPerView: 3,
            spaceBetween: 20,
            allowTouchMove: false,
          },
        }}>
        {children.map((child: JSX.Element) => (
          <SwiperSlide key={child.key} className={styles.CategoryPreviewWrapper__Carousel}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
